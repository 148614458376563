import React, { useEffect, useState } from 'react';
import { SelectableValue } from '@grafana/data';
import { FieldSet, Select, Field, Input, RangeSlider, Button } from '@grafana/ui';
import { PluginPage } from '@grafana/runtime';

export function ConfigureMaps() {
    let [maps, setMaps] = useState<any>({});
    let [selectedMap, setSelectedMap] = useState<any>();
    let [markers, setMarkers] = useState<any[]>([]);

    useEffect(() => {
        fetch('api/plugins/timbergrove-gemaps-app/resources/map')
            .then((response) => response.json())
            .then(({ maps }) => setMaps(maps));
    }, []);

    useEffect(() => {
        if (selectedMap) {
            setMarkers(
                maps[selectedMap].anchors.map(({ x, y, name }: any) => {
                    return makeMarker(x, y, name);
                })
            );
        }
    }, [maps, selectedMap]);

    function handleMapChange(selected: SelectableValue<string>) {
        if (!selected.value) {
            return;
        }

        setSelectedMap(selected.value);
    }

    function updateAnchor(anchor: any, index: number) {
        let mapsClone = structuredClone(maps);
        mapsClone[selectedMap].anchors[index] = anchor;

        setMaps(mapsClone);
    }

    function addAnchor() {
        let mapsClone = structuredClone(maps);
        mapsClone[selectedMap].anchors.push({
            name: "New anchor",
            x: 100,
            y: 100,
        });

        setMaps(mapsClone);
    }

    function removeAnchor(index: number) {
        let mapsClone = structuredClone(maps);
        mapsClone[selectedMap].anchors.splice(index, 1);
        setMaps(mapsClone);
    }

    function deleteMap() {
        let mapsClone = structuredClone(maps);
        delete mapsClone[selectedMap];

        setSelectedMap(null);

        setMaps(mapsClone);
    }

    function save() {
        fetch('api/plugins/timbergrove-gemaps-app/resources/map/save', {
            method: 'PUT',
            body: JSON.stringify({
                maps,
            }),
        });
    }

    return (
        <PluginPage>
            <FieldSet label="Map">
                <Field label="Map">
                    <Select
                        onChange={handleMapChange}
                        options={Object.keys(maps).map((value) => ({
                            label: value,
                            value,
                        }))}
                    />
                </Field>
                {selectedMap ? (
                    <div>
                        <div id="map-container">
                            <img src={maps[selectedMap].image} id="map-image" />
                            {markers.map((marker) => (
                                <>
                                    <div className="anchorMarker" style={{ left: `${marker.x}px`, top: `${marker.y}px` }}></div>
                                    <div
                                        className="label"
                                        style={{ left: `${marker.x + 15}px`, top: `${marker.y - 10}px`, color: '#555' }}
                                    >
                                        <span>{marker.name}</span>
                                    </div>
                                </>
                            ))}
                        </div>
                        <FieldSet label="Anchors">
                            {maps[selectedMap].anchors.map((anchor: any, i: number) => (
                                <>
                                    <Field label="Name">
                                        <Input
                                            width={60}
                                            name="anchor_name"
                                            value={anchor.name}
                                            onChange={(event) => updateAnchor({ ...anchor, name: event.currentTarget.value }, i)}
                                        />
                                    </Field>
                                    <Field label="X">
                                        <RangeSlider
                                            min={0}
                                            max={1000}
                                            value={anchor.x}
                                            onChange={(value) => updateAnchor({ ...anchor, x: value[0] }, i)}
                                        />
                                    </Field>
                                    <Field label="Y">
                                        <RangeSlider
                                            min={0}
                                            max={1000}
                                            value={anchor.y}
                                            onChange={(value) => updateAnchor({ ...anchor, y: value[0] }, i)}
                                        />
                                    </Field>

                                    <div>
                                        <Button onClick={() => removeAnchor(i)}>Remove Anchor</Button>
                                    </div>
                                </>
                            ))}
                            <Button onClick={addAnchor}>Add Anchor</Button>
                        </FieldSet>
                    </div>
                ) : (
                    ''
                )}
            </FieldSet>
            <div>
                <Button onClick={save}>Save</Button>
            </div>

            <div>
                <Button onClick={deleteMap}>
                    Delete this map
                </Button>
            </div>

            <style>
                {`
#map-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1280px;
}

#map-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* This will ensure that aspect ratio is maintained */
    opacity: 60%;
}
.anchorMarker {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: green;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

    /* Adjust for marker's dimensions */
    margin-top: -5px;
    margin-left: -5px;
}

.label {
    position: absolute;
    background-color: #fff;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
    /* Prevents text from wrapping */
}
`}
            </style>
        </PluginPage>
    );
}

function makeMarker(xm: number, ym: number, name: any) {
    const x = xm / 833;
    const y = ym / 833;

    if (x < 0 || x > 1 || y < 0 || y > 1) {
        console.warn('Coordinates must be between [0,1]');
        return;
    }

    const mapContainer = document.getElementById('map-container');
    const mapImage = document.getElementById('map-image');

    if (!mapContainer || !mapImage) {
        return;
    }

    const posX = x * mapContainer.offsetWidth;
    const posY = (1 - y) * mapContainer.offsetHeight;

    return {
        x: posX,
        y: posY,
        name,
    };
}
