import React, { useState } from 'react';
import { PluginPage } from '@grafana/runtime';
import { Button, Field, FieldSet, FileUpload, Input } from '@grafana/ui';

export function PageTwo() {
    let [name, setName] = useState("");
    let [image, setImage] = useState<any>();


    function save() {
        let form = new FormData();
        form.append("name", name);
        form.append("image", image);

        fetch("api/plugins/timbergrove-gemaps-app/resources/map/new", {
            method: "POST",
            body: form,
        });
    }

    return (
        <PluginPage>
            <FieldSet label="New map">
                <Field label="Name">
                    <Input width={60} onChange={(event) => setName(event.currentTarget.value)}/>
                </Field>

                <Field label="Image">
                    <FileUpload accept='jpeg,jpg,png' onFileUpload={(event) => {
                        let files = event.currentTarget.files;
                        if(files && files.length > 0) {
                            setImage(files[0])
                        }
                    }}/>
                </Field>
            </FieldSet>
            <Button onClick={save}>Save</Button>
        </PluginPage>
    );
}
